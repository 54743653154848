

















































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import * as types from '@/store/main/types';
import VMonthlyPicker from '@/components/VMonthlyPicker.vue';
import moment from 'moment';

import { stampToReadable } from '@/utilities/filters';

@Component( {
  components: { VMonthlyPicker },
  filters: { stampToReadable },
})
export default class Verhogingen extends Vue {

  @Getter('verhogingen', { namespace: 'main' }) private verhogingen!: string[][];
  @Action('loadVerhogingen', { namespace: 'main' }) private loadVerhogingen!: types.LoadVerhogingenAction;

  private searchText = '';
  private selectedMonth: moment.Moment = moment().locale('nl');
  private loadingVerhogingen = false;

  private created() {
    this.loadingVerhogingen = true;
    this.loadVerhogingen().finally(() => {
      this.loadingVerhogingen = false;
    });
  }

  private get trimmedSearch() {
    return this.searchText.trim().toLowerCase();
  }

  private get filteredVerhogingen() {
    const verhogingen: { [title: string]: string[][] } = {};
    this.verhogingen.filter((verhoging) => {
      const verhogingMonth = new Date(verhoging[0]);
      if (this.trimmedSearch.length > 0) {
        return ((verhoging[1] || '').toLowerCase().includes(this.trimmedSearch) ||
          (verhoging[2] || '').toLowerCase().includes(this.trimmedSearch));
      } else {
          return (verhogingMonth.getMonth() + 1).toString() === this.selectedMonth.format('M') &&
          verhogingMonth.getFullYear().toString() === this.selectedMonth.format('YYYY');
      }
    }).forEach((verhoging) => {
      if (verhoging[1] in verhogingen) {
        verhogingen[verhoging[1]].push(verhoging);
      } else {
        verhogingen[verhoging[1]] = [verhoging];
      }
    });
    Object.keys(verhogingen).forEach((key) => {
      verhogingen[key].sort((a, b) => {
        return new Date(a[0]) > new Date(b[0]) ? -1 : 1;
      });
    });
    return verhogingen;
  }

  private get filteredVerhogingenKeysOrderedByCaoDate() {
    const monthYearRe = /[0-9]{4}/;
    return Object.keys(this.filteredVerhogingen).sort(
      (a, b) => {
        const aClean = a.replace(' (niet aangemeld)', '');
        const bClean = b.replace(' (niet aangemeld)', '');
        const last4a = aClean.substring(Math.max(0, aClean.length - 4), aClean.length);
        const last4b = bClean.substring(Math.max(0, bClean.length - 4), bClean.length);
        if (
          !monthYearRe.test(last4a) ||
          !monthYearRe.test(last4b) ||
          aClean.substring(0, Math.max(0, aClean.length - 4)) !== bClean.substring(0, Math.max(0, bClean.length - 4))
        ) {
          return a > b ? 1 : -1;
        } else {
          return parseInt(last4a, 10) > parseInt(last4b, 10) ? -1 : 1;
        }
      });
  }
}

