import moment from 'moment';

export const toServerDateFormat = (dateString: string) => {
  return dateString.substring(6, 10) + '-' + dateString.substring(3, 5) + '-' + dateString.substring(0, 2);
};

export const wagesPer = (input: string) => {
  switch (input) {
    case 'year':
      return 'jaar';
    case 'hour':
      return 'uur';
    case '4weeks':
      return 'vier weken';
    case 'month':
      return 'maand';
    default:
      return input;
  }
};

export const toReadableDateFormat = (dateString: string) => {
  if (dateString === undefined || dateString === null) {
    return '';
  }
  return dateString.substring(8, 10) + '-' + dateString.substring(5, 7) + '-' + dateString.substring(0, 4);
};

export const stampToReadable = (timeStamp: string) => moment(new Date(timeStamp)).format('DD-MM-YYYY');

export const getCurrentDate = () => {
  const now = new Date(Date.now());
  let day = now.getDate().toString();
  let month = (now.getMonth() + 1).toString();
  if (day.length < 2) {
    day = '0' + day;
  }
  if (month.length < 2) {
    month = '0' + month;
  }
  return `${ day }-${ month }-${now.getFullYear() }`;
};

export const yesNo = (value: boolean) => value ? 'Ja' : 'Nee';
