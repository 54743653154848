












import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';

@Component
export default class VMonthlyPicker extends Vue {

  @Prop({ default: () => moment().locale('nl') }) private value!: moment.Moment;

  private minTime = moment().subtract(4, 'years');
  private monthLabels = [
    'Jan',
    'Feb',
    'Maart',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Aug',
    'Sept',
    'Okt',
    'Nov',
    'Dec',
  ];

}

